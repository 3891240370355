import Seo from "@components/Seo";
import { withWebLayout } from "@components/web/Decorators";
import { useQueryParameter } from "@hooks/navigation";
import styles from "./404.module.scss";
import NotFoundBaby from "./NotFoundBaby.svg";

export default withWebLayout(() => {
    const [fallbackUrl] = useQueryParameter("fallback");
    return <>
    <div className={styles.root}>
        <Seo noIndex title="Not found" />
        <div className={styles.image}>
            <img alt="Not found baby" src={NotFoundBaby} />
        </div>
    </div>
    <div className={styles.root}>
        <div className={styles.text}>
            <p>Vi kender det. Du kender det.</p>
            <p>Det er lige til at tude over, når <br></br> tingene bliver væk...</p>
        </div>
    </div>
</>;
});