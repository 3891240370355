import { useLocation } from "@reach/router";
import { useCallback, useMemo } from "react";

const useSearch = () => {
    const location = useLocation();
    return useMemo(
        () => {
            const result = new URLSearchParams(location.search);
            return result;
        },
        [location, location.search]);
}

export const useQueryParameters = <T = unknown>() => {
    const search = useSearch();
    return useMemo(
        () => {
            const result = {} as any;
            for(const key of search.keys())
                result[key] = search.get(key);

            return result as any as T;
        }, [search]);
}

export const useQueryParameter = (key: string) => {
    const location = useLocation();
    const search = useSearch();

    const valueFromQuery = useMemo(
        () => {
            const result = search.get(key);
            return result;
        }, 
        [search]);
        
    const updateQuery = useCallback((value: string) => {
        const newSearch = new URLSearchParams(search.toString());
        newSearch.set(key, value);

        if(newSearch.toString() === search.toString())
            return;

        let url = location.pathname;
        const searchString = newSearch.toString();
        if(searchString)
            url += "?" + searchString;

        window.location.href = url;
    }, []);

    return [valueFromQuery, updateQuery] as [string, typeof updateQuery];
}